import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/(login)": [~4],
		"/dashboard/(main)": [~5,[2]],
		"/dashboard/admin": [~7,[3]],
		"/dashboard/admin/add-balance": [~8,[3]],
		"/dashboard/admin/users": [~9,[3]],
		"/dashboard/admin/users/[id]": [~10,[3]],
		"/dashboard/(main)/chat/[id]": [~6,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';